'use client';

import React from 'react';

import { useTranslation } from 'react-i18next';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';

import SiteLinks from '@nm-namshi-frontend/core/page_components/shared/footer/SiteLinks';
import PaymentMethods from '@nm-namshi-frontend/core/page_components/shared/footer/SupportPaymentMethods';
import SocialMediaLinks from '@nm-namshi-frontend/core/page_components/shared/footer/SocialMediaLinks';
import DownloadApp from '@nm-namshi-frontend/core/page_components/shared/footer/DownloadAppInfo';
import { isSchmatalog } from '@nm-namshi-frontend/core/utils/helper';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';

import styles from './Footer.module.scss';
import LegalFooter from './LegalFooter';

type TProps = {
    withExternalLinks?: boolean; // If used outside of the -alog's
};

const AppFooter = ({ withExternalLinks }: TProps) => {
    const { t } = useTranslation();
    const isMobile = isSchmatalog();
    const pathName = usePathname();
    const isOnBagPage = pathName.includes('/bag');
    const { locale } = useAppPathname();
    const { apiRegion } = getLocaleConfig(locale);

    // const isOnBagPage = {}
    const ATCOverlapping = {
        paddingBottom: 30,
    };
    const footerStyles = {
        ...(isMobile && isOnBagPage ? ATCOverlapping : {}),
    };
    return (
        <div style={footerStyles}>
            <div className={styles.mainFooterWrapper}>
                <div className={clsx(styles.mainFooter, 'siteWidthContainer')}>
                    <SiteLinks isExternal={withExternalLinks} />
                    <div className={styles.storesAndSocial}>
                        <DownloadApp />
                        <SocialMediaLinks />
                    </div>
                </div>
            </div>
            <div className={styles.bottomFooterWrapper}>
                <div className={clsx(styles.bottomFooter, 'siteWidthContainer')}>
                    <div className={styles.paymentMethodsWrapper}>
                        <PaymentMethods />
                    </div>
                    <div className={styles.rightsReservedWrapper}>
                        &copy;{' '}
                        {t('footer.rights-reserved', {
                            year: new Date().getFullYear(),
                        })}
                    </div>
                    <div className={styles.ownedByWrapper}>{t('footer.owned-by-noon')}</div>
                </div>
                {apiRegion === 'sa' && <LegalFooter />}
            </div>
        </div>
    );
};

export default React.memo(AppFooter);
