import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/navigation';

import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import { getAllRegionCodes, getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';

import LINKS_GROUPS from './LinksGroups';
import styles from './SiteLinks.module.scss';
import AlteredLink from '../AlteredLink';

type TProps = {
    isExternal?: boolean;
};

export default function SiteLinks({ isExternal = false }: TProps) {
    const { t } = useTranslation();
    const { locale } = useAppPathname();
    const regionCodeArray = getAllRegionCodes();
    const { lang, seoRegion } = getLocaleConfig(locale);
    const router = useRouter();

    const renderFooterLinks = () => (
        <>
            <div className={styles.linksGroup}>
                <h4 className={styles.heading}>{t('footer.other-countries')}</h4>
                {regionCodeArray.map(({ apiRegionCode, seoRegionCode }) => (
                    <div key={apiRegionCode} className={styles.link}>
                        <AlteredLink locale={locale} href={`/${seoRegionCode}-${lang}/`}>
                            {t(`location-selector.country-name-${seoRegionCode}`)}
                        </AlteredLink>
                    </div>
                ))}
            </div>
            {LINKS_GROUPS.map((group) => (
                <div key={group.name} className={styles.linksGroup}>
                    <h4 className={styles.heading}>{t(group.name)}</h4>
                    {group.links
                        .filter((link) => {
                            if (link?.shown) {
                                return link.shown(locale);
                            }
                            return true;
                        })
                        .map((link) => (
                            <div key={link.name} className={styles.link}>
                                <AlteredLink
                                    target={link.newTab ? '_blank' : ''}
                                    locale={locale}
                                    href={link.href(isExternal, locale, seoRegion)}
                                    onClick={() => {
                                        if (link.refreshRouter) {
                                            // Triggering a manual refresh of router to force some links(like /brands) to reload everytime
                                            router.refresh();
                                        }
                                    }}
                                >
                                    {t(link.name)}
                                </AlteredLink>
                            </div>
                        ))}
                </div>
            ))}
        </>
    );

    return <div className={styles.groupsContainer}>{renderFooterLinks()}</div>;
}
