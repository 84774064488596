'use client';

// Directly hooks up to the layout store to show loading animation
// This component must be placed in the layout, *alongside* the main component
// ^ This helps avoid unnecessary rerenders from state at the layout level

// AnimatePresence --> adds in and removes the div from the React tree nicely

import React from 'react';

import useLayoutStore from '@nm-namshi-frontend/core/stores/useLayoutStore';
import LoaderLogo from '@nm-namshi-frontend/core/components/loaders/LoaderLogo';

import styles from './FullScreenLoader.module.scss';

const FullScreenLoader = () => {
    const { isFullscreenLoaderShown } = useLayoutStore();

    if (!isFullscreenLoaderShown) return null;

    return (
        <div className={styles.container}>
            <LoaderLogo />
        </div>
    );
};

export default FullScreenLoader;
