import useCustomerStore from '@nm-namshi-frontend/core/stores/useCustomerStore';
import { setCookie } from '@nm-namshi-frontend/core/utils/helper';
import { COOKIE_ID } from '@nm-namshi-frontend/core/constants/uiConstants';

/**
 * Custom hook to help updating both cookie and store for addressKey
 */
const useUpdateAddressKey = () => {
    const { setSelectedAddressKey } = useCustomerStore();

    const updateAddressKey = (addressKeyValue: string) => {
        setSelectedAddressKey(addressKeyValue);
        setCookie(COOKIE_ID.X_ADDRESS_KEY, addressKeyValue);
    };

    return { updateAddressKey };
};

export default useUpdateAddressKey;
