import React from 'react';

import { REGIONCONFIG } from '@nm-namshi-frontend/core/config';
import { getLocaleConfig } from '@nm-namshi-frontend/core/utils/locale';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';
import Icon from '@nm-namshi-frontend/core/components/Icon';

import styles from './SupportPaymentMethods.module.scss';

type TProps = {
    method: string;
};

type TPaymentMethod = 'tabby' | 'tamara' | 'visa' | 'mastercard' | 'amex' | 'cod' | 'apple-pay';

export default function SupportPaymentMethods() {
    const { locale } = useAppPathname();
    const { seoRegion } = getLocaleConfig(locale);
    const { paymentIcons } = REGIONCONFIG[seoRegion] ?? {};

    const PaymentIcon = ({ method }: TProps) => {
        const tmethod = method as TPaymentMethod;
        switch (tmethod) {
            case 'mastercard':
                return <Icon size={34} name="mastercardLogo" loading="lazy" />;
            case 'visa':
                return <Icon size={34} name="visacardLogo" loading="lazy" />;
            case 'tabby':
                return <Icon size={44} name="tabby" loading="lazy" />;
            case 'tamara':
                return <Icon size={44} name="Tamara" loading="lazy" />;
            case 'cod':
                return <Icon size={34} name="codGreen" loading="lazy" />;
            case 'amex':
                return <Icon size={34} name="amex" loading="lazy" />;
            case 'apple-pay':
                return <Icon size={34} name="applePay" loading="lazy" />;
            default:
                return <Icon size={34} name={tmethod} loading="lazy" />;
        }
    };
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                {paymentIcons?.map((method) => (
                    <PaymentIcon key={method} method={method} />
                ))}
            </div>
        </div>
    );
}
