import { getExternalHref } from '@nm-namshi-frontend/core/utils/url';
import {
    ABOUT_US,
    ADIDAS,
    BRANDS,
    CONSUMER_RIGHTS,
    HELP_CENTRE_BASEURL,
    HELP_CENTRE_LINKS,
    GINGER,
    LACOSTE,
    MAKEUP,
    MENS_CLOTHING,
    MENS_SNEAKERS,
    NIKE,
    PARTNERSHIPS,
    PRESS,
    SEVENTY_FIVE,
    TSHIRTS,
    WOMENS_CLOTHING,
    WOMENS_SHOES,
    WOMENS_SNEAKERS,
    PRIVACY_POLICY,
    BLACK_NOVEMBER,
} from '@nm-namshi-frontend/core/config';
import { ClientApp, TLocaleSEO, TRegionSEO } from '@nm-namshi-frontend/core/types';
import { getLocaleConfig, getLocaleLanguage } from '@nm-namshi-frontend/core/utils/locale';

export type TFooterLink = {
    name: string;
    href: (isExternal: boolean, locale: TLocaleSEO, seoRegion: TRegionSEO) => string;
    shown?: (locale: TLocaleSEO) => boolean;
    newTab?: boolean;
    refreshRouter?: boolean;
};

export type TLinksGroup = {
    name: string;
    links: TFooterLink[];
};

export const getPrivacyPolicyUrl = (isExternal: boolean, locale: TLocaleSEO, seoRegion: TRegionSEO) => {
    if (['uae', 'saudi'].includes(seoRegion)) return PRIVACY_POLICY;

    return getHelpCenterLink(locale, 'privacy_policy');
};

export const TERMS_AND_CONDITIONS = (isExternal: boolean, locale: TLocaleSEO) =>
    getHelpCenterLink(locale, 'terms_and_conditions');

const LINKS_GROUPS: TLinksGroup[] = [
    {
        name: 'footer.about-us',
        links: [
            {
                name: 'footer.about-us',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, ABOUT_US) : ABOUT_US,
            },
            {
                name: 'footer.partnerships',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal
                        ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, PARTNERSHIPS)
                        : PARTNERSHIPS,
            },
            {
                name: 'footer.press',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, PRESS) : PRESS,
            },
            {
                name: 'footer.privacy-policy',
                href: getPrivacyPolicyUrl,
                newTab: true,
            },
            {
                name: 'footer.consumer-rights',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal
                        ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, CONSUMER_RIGHTS)
                        : CONSUMER_RIGHTS,
                shown: (locale: TLocaleSEO) => {
                    const { isUAE } = getLocaleConfig(locale);
                    return isUAE;
                },
            },
        ],
    },
    {
        name: 'footer.customer-service',
        links: [
            {
                name: 'footer.how-track-order',
                href: (isExternal, locale) => getHelpCenterLink(locale, 'order_tracking'),
                newTab: true,
            },
            {
                name: 'footer.how-return-order',
                href: (isExternal: boolean, locale: TLocaleSEO) => getHelpCenterLink(locale, 'order_returns'),
                newTab: true,
            },
            {
                name: 'footer.refund',
                href: (isExternal: boolean, locale: TLocaleSEO) => getHelpCenterLink(locale, 'refund'),
                newTab: true,
            },
            {
                name: 'footer.info-on-namshi-products',
                href: (isExternal: boolean, locale: TLocaleSEO) => getHelpCenterLink(locale, 'info'),
                newTab: true,
            },
            {
                name: 'footer.faqs',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    `https://help.namshi.com/portal/${getLocaleLanguage(locale)}/home`,
                newTab: true,
            },
            {
                name: 'footer.delivery',
                href: (isExternal: boolean, locale: TLocaleSEO) => getHelpCenterLink(locale, 'delivery'),
                newTab: true,
            },
            {
                name: 'footer.payment-methods',
                href: (isExternal: boolean, locale: TLocaleSEO) => getHelpCenterLink(locale, 'payment_methods'),
                newTab: true,
            },
            {
                name: 'footer.returns',
                href: (isExternal: boolean, locale: TLocaleSEO) => getHelpCenterLink(locale, 'returns'),
                newTab: true,
            },
        ],
    },
    {
        name: 'footer.top-categories',
        links: [
            {
                name: 'footer.black-november',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal
                        ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, BLACK_NOVEMBER)
                        : BLACK_NOVEMBER,
            },
            {
                name: 'footer.womens-clothing',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal
                        ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, WOMENS_CLOTHING)
                        : WOMENS_CLOTHING,
            },
            {
                name: 'footer.mens-clothing',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal
                        ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, MENS_CLOTHING)
                        : MENS_CLOTHING,
            },
            {
                name: 'footer.mens-sneakers',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal
                        ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, MENS_SNEAKERS)
                        : MENS_SNEAKERS,
            },
            {
                name: 'footer.womens-sneakers',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal
                        ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, WOMENS_SNEAKERS)
                        : WOMENS_SNEAKERS,
            },
            {
                name: 'footer.tshirts',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, TSHIRTS) : TSHIRTS,
            },
            {
                name: 'footer.makeup',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, MAKEUP) : MAKEUP,
            },
            {
                name: 'footer.womens-shoes',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal
                        ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, WOMENS_SHOES)
                        : WOMENS_SHOES,
            },
        ],
    },
    {
        name: 'footer.top-brands',
        links: [
            {
                name: 'footer.ginger',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, GINGER) : GINGER,
            },
            {
                name: 'footer.adidas',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, ADIDAS) : ADIDAS,
            },
            {
                name: 'footer.nike',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, NIKE) : NIKE,
            },
            {
                name: 'footer.seventy-five',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal
                        ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, SEVENTY_FIVE)
                        : SEVENTY_FIVE,
            },
            {
                name: 'footer.lacoste',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, LACOSTE) : LACOSTE,
            },
            {
                name: 'allBrands',
                href: (isExternal: boolean, locale: TLocaleSEO) =>
                    isExternal ? getExternalHref(ClientApp.ACCOUNT, ClientApp.DEFAULT, locale, BRANDS) : BRANDS,
                refreshRouter: true,
            },
        ],
    },
];

/**
 * Returns a link to namshi help center
 * @param {TLocaleSEO} locale - Locale
 * @param {keyof typeof HELP_CENTRE_LINKS.en} linkKey Link key name
 * @returns {string}
 */
export const getHelpCenterLink = (locale: TLocaleSEO, linkKey: keyof typeof HELP_CENTRE_LINKS.en) =>
    `${HELP_CENTRE_BASEURL}${HELP_CENTRE_LINKS[getLocaleLanguage(locale)][linkKey]}`;

export default LINKS_GROUPS;
