/* eslint-disable @next/next/no-img-element */
// ^^ adding these as icons when hanaa is ready
import React from 'react';

import { useTranslation } from 'react-i18next';

import {
    FACEBOOK_LINK,
    INSTAGRAM_LINK,
    NAMSHI_LINKEDIN,
    SNAPCHAT_LINK,
    TWITTER_LINK,
    YOUTUBE_LINK,
    PINTEREST_LINK,
} from '@nm-namshi-frontend/core/config';
import Icon from '@nm-namshi-frontend/core/components/Icon';
import useAppPathname from '@nm-namshi-frontend/core/hooks/useAppPathname';

import styles from './SocialMediaLinks.module.scss';
import AlteredLink from '../AlteredLink';

export default function SocialMediaLinks() {
    const { t } = useTranslation();
    const { locale } = useAppPathname();

    return (
        <div className={styles.container}>
            <h4 className={styles.heading}>{t('footer.follow-us')}</h4>
            <div className={styles.links}>
                <AlteredLink locale={locale} href={FACEBOOK_LINK} target="_blank">
                    <Icon name="facebookFilled" size={36} loading="lazy" />
                </AlteredLink>
                <AlteredLink locale={locale} href={TWITTER_LINK} target="_blank">
                    <Icon name="twitterFilled" size={36} loading="lazy" />
                </AlteredLink>
                <AlteredLink locale={locale} href={NAMSHI_LINKEDIN} target="_blank">
                    <Icon name="linkedinFilled" size={36} loading="lazy" />
                </AlteredLink>
                <AlteredLink locale={locale} href={INSTAGRAM_LINK} target="_blank">
                    <Icon name="instagramFilled" size={36} loading="lazy" />
                </AlteredLink>
                <AlteredLink locale={locale} href={YOUTUBE_LINK} target="_blank">
                    <Icon name="youtubeFilled" size={36} loading="lazy" />
                </AlteredLink>
                <AlteredLink locale={locale} href={SNAPCHAT_LINK} target="_blank">
                    <Icon name="snapchatFilled" size={36} loading="lazy" />
                </AlteredLink>
                <AlteredLink locale={locale} href={PINTEREST_LINK} target="_blank">
                    <Icon name="pinterestFilled" size={36} loading="lazy" />
                </AlteredLink>
            </div>
        </div>
    );
}
