import React from 'react';

import { useTranslation } from 'react-i18next';

import { redirectTo } from '@nm-namshi-frontend/core/utils/url';
import { APPSTORE_LINK, HUAWEI_APP_GALLERY_LINK, PLAYSTORE_LINK } from '@nm-namshi-frontend/core/config';
import getImages from '@nm-namshi-frontend/core/utils/images';

import styles from './DownloadAppInfo.module.scss';

export default function DownloadAppInfo() {
    const { t } = useTranslation();
    return (
        <div className={styles.container}>
            <h4 className={styles.heading}>{t('footer.shop-on-the-go')}</h4>
            <div className={styles.links}>
                <button type="button" onClick={redirectTo(PLAYSTORE_LINK)}>
                    <img src={getImages().misc.googlePlay} alt="Google Play" height={35} width={120} loading="lazy" />
                </button>
                <button type="button" onClick={redirectTo(APPSTORE_LINK)}>
                    <img src={getImages().misc.appStore} alt="App Store" height={35} width={120} loading="lazy" />
                </button>
                <button type="button" onClick={redirectTo(HUAWEI_APP_GALLERY_LINK)}>
                    <img
                        src={getImages().misc.huaweiAppGallery}
                        alt="Huawei App Gallery"
                        height={35}
                        width={120}
                        loading="lazy"
                    />
                </button>
            </div>
        </div>
    );
}
