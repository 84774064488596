import React from 'react';

import clsx from 'clsx';

import styles from './LoaderLogo.module.scss';

type TProps = {
    isNotFullscreen?: boolean;
};

const LoaderLogo = ({ isNotFullscreen }: TProps): JSX.Element => (
    <div className={clsx(isNotFullscreen ? styles.inlineLoader : styles.fullScreenLoader)}>
        <div className={styles.namshiRipple}>
            <div className={styles.ripple} />
            <div className={styles.ripple} />
        </div>
    </div>
);

export default LoaderLogo;
