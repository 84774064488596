import React, { useEffect, useRef } from 'react';

import clsx from 'clsx';

import FieldLayout from '@nm-namshi-frontend/core/components/forms/FieldLayout';
import { TConfigIcons } from '@nm-namshi-frontend/core/theme/icon';
import Icon, { TColors } from '@nm-namshi-frontend/core/components/Icon';

import styles from './TextInput.module.scss';
import Loader from '../loaders/Loader';

export enum TextInputIconPositions {
    START = 'start',
    END = 'end',
}

type TProps = {
    autofocus?: boolean;
    className?: string;
    containerClassName?: string;
    disabled?: boolean;
    displayIcon?: TConfigIcons;
    displayIconColor?: TColors;
    displayIconPosition?: TextInputIconPositions;
    displayIconSize?: number;
    error?: string | null;
    helperText?: string;
    inputClassName?: string;
    isPhoneInput?: boolean;
    label?: string;
    maxLength?: number;
    minLength?: number;
    name?: string;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClear?: () => void;
    placeholder?: string;
    tabIndex?: number;
    type?: 'date' | 'datetime-local' | 'search' | 'text' | 'number'; // @TODO : Ehab - Move Search based TextInput to its own component, as the customization done here, may not be required as per TextInput designs
    value: string;
    valid?: boolean; // Display a success state based on external input : Useful in case of form elements where one might wanna check if there is no error state AND the input has not been touched
    inputMode?: 'search' | 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal';
    bottomHelperText?: string;
    variant?: 'boxy' | 'minimal';
    loading?: boolean;
    max?: string;
    min?: string;
};

const TextInput = ({
    autofocus = false,
    bottomHelperText,
    className,
    containerClassName,
    disabled = false,
    displayIcon,
    displayIconColor = 'offBlack',
    displayIconPosition = TextInputIconPositions.START,
    displayIconSize = 24,
    error,
    helperText,
    inputClassName,
    inputMode,
    isPhoneInput,
    label,
    loading,
    max,
    maxLength,
    min,
    minLength,
    name = '',
    onBlur,
    onChange,
    onClear,
    onFocus,
    onKeyDown,
    placeholder,
    tabIndex,
    type = 'text',
    valid,
    value,
    variant = 'boxy',
}: TProps): JSX.Element => {
    const ref = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (ref.current && autofocus) {
            ref.current.focus();
        }
    }, [autofocus]);

    const handleClear = () => {
        if (!onClear || loading) return;

        onClear();
        if (ref.current) {
            ref.current.focus();
        }
    };

    // Renderers
    const renderStartIcon = () => {
        if (displayIcon && displayIconPosition === TextInputIconPositions.START) {
            return (
                <span
                    className={clsx(
                        styles.displayIcon,
                        styles.displayIconStart,
                        variant === 'minimal' && styles.minimal,
                    )}
                >
                    <Icon name={displayIcon} size={displayIconSize} color={displayIconColor} />
                </span>
            );
        }

        return null;
    };

    const renderInput = () => {
        const hasStartDisplayIcon = !!displayIcon && displayIconPosition === TextInputIconPositions.START;
        return (
            <input
                {...(inputMode ? { inputMode } : {})}
                autoComplete="off"
                className={clsx(
                    styles.input,
                    !!onClear && styles.hasClear,
                    isPhoneInput && styles.phoneInput,
                    hasStartDisplayIcon && styles.hasStartDisplayIcon,
                    valid && 'valid',
                    error && 'error',
                    inputClassName,
                    variant === 'minimal' && styles.minimal,
                )}
                disabled={disabled}
                name={name}
                maxLength={maxLength}
                minLength={minLength}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                ref={ref}
                tabIndex={tabIndex}
                type={type}
                value={value}
                max={max}
                min={min}
            />
        );
    };

    const renderEndIcon = () => {
        if (displayIcon && displayIconPosition === TextInputIconPositions.END) {
            return (
                <span
                    className={clsx(styles.displayIcon, styles.displayIconEnd, variant === 'minimal' && styles.minimal)}
                >
                    <Icon name={displayIcon} size={displayIconSize} color={displayIconColor} />
                </span>
            );
        }
        if (value) {
            if (onClear && !disabled) {
                return (
                    <button
                        className={clsx(styles.clear, { [styles.phoneInput]: isPhoneInput })}
                        type="button"
                        onClick={handleClear}
                        tabIndex={-1}
                    >
                        {!loading ? <Icon name="close" size={16} color={displayIconColor} /> : <Loader />}
                    </button>
                );
            }
        }

        return null;
    };

    return (
        <FieldLayout
            containerClass={containerClassName}
            label={label}
            error={error}
            helperText={helperText}
            bottomHelperText={bottomHelperText}
        >
            <div className={clsx(styles.inputContainer, className)}>
                {renderStartIcon()}
                {renderInput()}
                {renderEndIcon()}
            </div>
        </FieldLayout>
    );
};

export default TextInput;
