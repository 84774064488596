import React from 'react';

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { SAUDI_CR, SAUDI_VAT } from '@nm-namshi-frontend/core/config';

import styles from './LegalFooter.module.scss';

const LegalFooter = () => {
    const { t } = useTranslation('common');

    return (
        <div className={styles.container}>
            <div className={clsx(styles.innerContainer, 'siteWidthContainer')}>
                <span>{t('legalFooter')}</span>
                <span className={styles.cr}>{t('crNo', { value: SAUDI_CR })}</span>
                <span>{t('vatNo', { value: SAUDI_VAT })}</span>
            </div>
        </div>
    );
};

export default LegalFooter;
